<template>
    <div>
        <v-container>
        <div class="hidden-sm-and-down"> <!--full screen --> 
        <div class="container">
                <div class="centro">
                    <div v-if="verify==0">
                        <v-row>
                            <v-col cols="12" md="12" class="text-center">
                                <!-- <v-icon x-large color="red">cancel</v-icon> -->
                                <h4>Pago seguro con:</h4>
                                <div style="width:100%" class="text-center">
                                    <img src="@/assets/logo-web-pay-plus.png"/>
                                </div>
                            </v-col>
                            <v-col cols="12" md="12" class="text-center">
                                <strong>Estamos validando tu solicitud</strong>
                            </v-col>
                            <v-col cols="12" md="12" class="text-center">
                                <v-progress-linear class="mt-5" indeterminate color="orange"></v-progress-linear>
                            </v-col>
                        </v-row>
                    </div>
                    <div v-if="verify==1">
                        <v-row>
                            <v-col cols="12" md="12" class="text-center">
                                <v-avatar size="80" color="white">
                                    <v-img src="@/assets/transaccion_ok.png" alt=""></v-img>
                                </v-avatar>
                            </v-col>
                            <v-col cols="12" md="12" class="text-center">
                                <h1 class="color-black">Comprobante de pago</h1>
                            </v-col>
                            <v-col cols="12" md="12" class="mt-n5 text-center">
                                <h2 class="color-black">¡Hola!</h2>
                                <h4 class="color-black">Tu pago a traves de nuestro sitio web fue exitoso</h4>
                            </v-col>
                            <v-col cols="12" md="12" class="text-center" style="background:#0a2f42">
                                <h4 class="white--text">Detalle de compra:</h4>
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-row>
                                    <v-col cols="8" md="8"><strong>Producto</strong></v-col>
                                    <v-col cols="2" md="2" class="text-center"><strong>Cantidad</strong></v-col>
                                    <v-col cols="2" md="2" class="text-center"><strong>Precio</strong></v-col>
                                </v-row>                            
                                <v-row class="mt-n5" v-for="(item,i) in detalle" :key="i">
                                    <v-col cols="8" md="8">{{item.producto}}</v-col>
                                    <v-col cols="2" md="2" class="text-center">{{new Intl.NumberFormat("de-DE").format(parseFloat(item.cantidad))}}</v-col>
                                    <v-col cols="2" md="2" class="text-center">${{new Intl.NumberFormat("de-DE").format(parseFloat(item.precio))}}</v-col>
                                </v-row>                            
                            </v-col>
                            <v-col cols="12" md="12" class="text-center" style="background:#0a2f42">
                                <h4 class="white--text">Detalle del pago:</h4>
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-row class="mt-2">
                                    <v-col class="mt-n5" cols="6" md="6">N° de orden</v-col>
                                    <v-col class="mt-n5" cols="6" md="6">{{orden}}</v-col>
                                    <v-col class="mt-n5" cols="6" md="6">N° de Tarjeta</v-col>
                                    <v-col class="mt-n5" cols="6" md="6">{{tarjeta}}</v-col>
                                    <v-col class="mt-n5" cols="6" md="6">Fecha operación</v-col>
                                    <v-col class="mt-n5" cols="6" md="6">{{fecha}}</v-col>
                                    <v-col class="mt-n5" cols="6" md="6">Hora operación</v-col>
                                    <v-col class="mt-n5" cols="6" md="6">{{hora}}</v-col>
                                    <v-col class="mt-n5" cols="6" md="6">Monto pagado</v-col>
                                    <v-col class="mt-n5" cols="6" md="6">${{new Intl.NumberFormat("de-DE").format(parseFloat(monto))}}</v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="12" class="text-center" style="height:10px; background:#0a2f42">&nbsp;</v-col>
                            <v-col cols="6" md="5" class="text-end">
                                <v-text-field prepend-inner-icon="send" class="text-caption" v-model="form_email" placeholder="Enviar comprobante por email" outlined dense rounded clearable></v-text-field>
                            </v-col>
                            <v-col cols="6" md="3" class="mt-1 text-start">
                                <v-btn @click="enviar_comprobante()" small :color="black" dark rounded>Enviar</v-btn>
                                <h6 v-if="send==0" class="white--text">&nbsp;</h6>
                                <h6 v-if="send==1" class="green--text">Comprobante Enviado</h6>
                                <h6 v-if="send==2" class="red--text">Comprobante no Enviado</h6>
                            </v-col>
                            <v-col cols="12" md="4" class="text-center">
                                <v-btn @click="home()" :color="black" dark rounded>Volver al comercio</v-btn>
                            </v-col>
                        </v-row>
                </div>
                    <div v-if="verify==2">
                        <v-row>
                            <v-col cols="12" md="12" class="text-center">
                                <v-avatar size="100" color="white">
                                    <v-img src="@/assets/transaccion_error.png" alt=""></v-img>
                                </v-avatar>
                            </v-col>
                            <v-col cols="12" md="12" class="text-center">
                                <h2 class="color-black">¡Hola!</h2>
                                <h4 class="color-black">Ha ocurrido un problema</h4>
                            </v-col>
                            <v-col cols="12" md="12" class="text-center" style="background:#0a2f42">
                                <h4 class="white--text">Este es el Detalle:</h4>
                            </v-col>
                            <v-col cols="12" md="12">
                                <h4>{{respuesta}}</h4>
                            </v-col>
                            <v-col cols="12" md="12" class="text-center">
                                <v-btn @click="home()" :color="black" dark rounded>Volver al comercio</v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </div>   
        </div>
        <div class="hidden-sm-and-up"> <!--mobile screen-->
            <div v-if="verify==0">
                <v-row>
                    <v-col cols="12" md="12" class="text-center">
                        <!-- <v-icon x-large color="red">cancel</v-icon> -->
                        <h4>Pago seguro con:</h4>
                        <div style="width:100%" class="text-center">
                            <img src="@/assets/logo-web-pay-plus.png"/>
                        </div>
                    </v-col>
                    <v-col cols="12" md="12" class="text-center">
                        <strong>Estamos validando tu solicitud</strong>
                    </v-col>
                    <v-col cols="12" md="12" class="text-center">
                        <v-progress-linear class="mt-5" indeterminate color="orange"></v-progress-linear>
                    </v-col>
                </v-row>
            </div>
            <div v-if="verify==1">
                <v-row>
                    <v-col cols="12" md="12" class="text-center">
                        <v-avatar size="50" color="white">
                            <v-img src="@/assets/transaccion_ok.png" alt=""></v-img>
                        </v-avatar>
                    </v-col>
                    <v-col cols="12" md="12" class="text-center">
                        <h1 class="color-black">Comprobante de pago</h1>
                    </v-col>
                    <v-col cols="12" md="12" class=" mt-n5 text-center">
                        <h2 class="color-black">¡Hola!</h2>
                        <h4 class="color-black">Tu pago a traves de nuestro sitio web fue exitoso</h4>
                    </v-col>
                    <v-col cols="12" md="12" class="text-center" style="background:#0a2f42">
                        <h4 class="white--text">Detalle de compra:</h4>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-row>
                            <v-col cols="6" md="6"><strong>Producto</strong></v-col>
                            <v-col cols="3" md="3" class="text-center"><strong>Cantidad</strong></v-col>
                            <v-col cols="3" md="3" class="text-center"><strong>Precio</strong></v-col>
                        </v-row>                            
                        <v-row class="mt-n5" v-for="(item,i) in detalle" :key="i">
                            <v-col cols="6" md="6">{{item.producto}}</v-col>
                            <v-col cols="3" md="3" class="text-center">{{new Intl.NumberFormat("de-DE").format(parseFloat(item.cantidad))}}</v-col>
                            <v-col cols="3" md="3" class="text-center">${{new Intl.NumberFormat("de-DE").format(parseFloat(item.precio))}}</v-col>
                        </v-row>                            
                    </v-col>
                    <v-col cols="12" md="12" class="text-center" style="background:#0a2f42">
                        <h4 class="white--text">Detalle del pago:</h4>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-row class="mt-2">
                            <v-col class="mt-n5" cols="6" md="6">N° de orden</v-col>
                            <v-col class="mt-n5" cols="6" md="6">{{orden}}</v-col>
                            <v-col class="mt-n5" cols="6" md="6">N° de Tarjeta</v-col>
                            <v-col class="mt-n5" cols="6" md="6">{{tarjeta}}</v-col>
                            <v-col class="mt-n5" cols="6" md="6">Fecha operación</v-col>
                            <v-col class="mt-n5" cols="6" md="6">{{fecha}}</v-col>
                            <v-col class="mt-n5" cols="6" md="6">Hora operación</v-col>
                            <v-col class="mt-n5" cols="6" md="6">{{hora}}</v-col>
                            <v-col class="mt-n5" cols="6" md="6">Monto pagado</v-col>
                            <v-col class="mt-n5" cols="6" md="6">${{new Intl.NumberFormat("de-DE").format(parseFloat(monto))}}</v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="12" class="text-center" style="height:10px; background:#0a2f42">&nbsp;</v-col>
                    <v-col cols="9" md="5" class="text-end">
                        <v-text-field prepend-inner-icon="send" class="text-caption" v-model="form_email" placeholder="Enviar comprobante por email" outlined dense rounded clearable></v-text-field>
                    </v-col>
                    <v-col cols="3" md="3" class="mt-1 text-center">
                        <v-btn @click="enviar_comprobante()" small :color="black" dark rounded>Enviar</v-btn>
                        <h6 v-if="send==0" class="white--text">&nbsp;</h6>
                        <h6 v-if="send==1" class="green--text">Comprobante Enviado</h6>
                        <h6 v-if="send==2" class="red--text">Comprobante no Enviado</h6>
                    </v-col>
                    <v-col cols="12" md="4" class="mt-n5 text-center">
                        <v-btn @click="home()" :color="black" dark rounded>Volver al comercio</v-btn>
                    </v-col>
                    <!-- <v-col cols="12" md="12" class="text-center">
                        <v-btn @click="home()" :color="black" dark rounded>Volver al comercio</v-btn>
                    </v-col> -->
                </v-row>
            </div>
            <div v-if="verify==2">
                <v-row>
                    <v-col cols="12" md="12" class="text-center">
                        <v-avatar size="100" color="white">
                            <v-img src="@/assets/transaccion_error.png" alt=""></v-img>
                        </v-avatar>
                    </v-col>
                    <v-col cols="12" md="12" class="text-center">
                        <h2 class="color-black">¡Hola!</h2>
                        <h4 class="color-black">Ha ocurrido un problema</h4>
                    </v-col>
                    <v-col cols="12" md="12" class="text-center" style="background:#0a2f42">
                        <h4 class="white--text">Este es el Detalle:</h4>
                    </v-col>
                    <v-col cols="12" md="12" class="text-center">
                        <h4 class="mt-5 mb-5">{{respuesta}}</h4>
                    </v-col>
                    <v-col cols="12" md="12" class="text-center">
                        <v-btn @click="home()" :color="black" dark rounded>Volver al comercio</v-btn>
                    </v-col>
                </v-row>
            </div>
        </div>
        </v-container>
    </div>
</template>
<style scoped>
.contenedor {
  display: flex;
  justify-content: center;
  align-items: center;
}
.centro{
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.color-black{
    color:#0a2f42;
}
.color-dorado{
    color:#e1c655;
}
</style>
<script>
import axios from 'axios';
export default {
    name:'Pagos',
    data: () => ({
        dorado:"#e1c655",
        black:"#0a2f42",
        //contrato:'INV-1004-12345', //test
        contrato:'INV-1012-20490',
        token:'',
        tkn:'',
        verify:1,  // 0 validando; 1 exito; 2 error
        //codigos NIVEL 2 revisar apartado transbank developer codigos de error
        //esta activacion de nivel 2 debe ser realizado por el cliente de transbank
        response_codes:[
            //{ id: 0, txt:'Transacción exitosa'},
            { id:-1, txt:'Tarjeta inválida'},
            { id:-2, txt:'Error de conexión'},
            { id:-3, txt:'Excede monto máximo'},
            { id:-4, txt:'Fecha de expiración inválida'},
            { id:-5, txt:'Problema en autenticación'},
            { id:-6, txt:'Rechazo general'},
            { id:-7, txt:'Tarjeta bloqueada'},
            { id:-8, txt:'Tarjeta vencida'},
            { id:-9, txt:'Transacción no soportada'},
            { id:-10, txt:'Problema en la transacción'},
            { id:-11, txt:'Excede límite de reintentos de rechazos'},
        ],
        codigo:null,
        respuesta:null,
        // datos de la orden y transaccion
        orden:'',
        tarjeta:'',
        fecha:'',
        hora:'',
        monto:'',
        detalle:[],
        form_email:'',
        send:0
    }),
    async mounted(){
        // this.contrato='INV-1004-12345';
        // this.orden='991717015544718';
        this.tkn = this.$route.params.tk;
        this.token=localStorage.getItem('tokenCompra');
        if (this.tkn=this.token){
            if (this.token!='' && this.token!=null){
                this.verify=0
                this.verificarPago()
            } 
        }
    },
    methods:{
        home(){
            this.$router.push({name:'Home'});
        },
        async verificarPago(){
            try {
                const params={ 
                    'token': this.token,
                    'contrato': this.contrato
                };
                //const response = await axios.post('https://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/transbank/webpayplus/test/verify/',params);
                const response = await axios.post('https://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/transbank/webpayplus/verify/',params);
                const datos=response.data;
                console.log(datos);
                // this.storeLocal=localStorage.removeItem('transbank');
                // this.storeLocal=localStorage.setItem('transbank',JSON.stringify(datos));
                if(datos.response_code<=0){
                    //datos de la tranzasccion
                    const oc=datos.buy_order;
                    this.fecha=this.format_fecha(datos.transaction_date);
                    this.hora=this.format_hora(datos.transaction_date);
                    this.tarjeta=datos.card_detail.card_number;
                    this.monto=datos.amount;
                    this.orden=oc.substring(3,oc.length);
                    //analizamos el codigo de respuesta
                    this.codigo=datos.response_code;
                    if(this.codigo!=0){
                        const found = this.response_codes.filter(res => parseInt(res.id)==parseInt(this.codigo));
                        console.log(found);
                        if(found.length!=0){
                            console.log('condigo de error: '+ this.codigo);
                            this.respuesta=found[0].txt;
                            this.verify=2
                        } else {
                            console.log('condigo de error: '+ this.codigo);
                            this.respuesta='Inconsistencia en la transacción'
                            this.verify=2
                        }
                    } else {
                        console.log('codigo exitoso: '+ this.codigo);
                        //datos del pedido
                        const response1 = await axios.get('https://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/leer/ordenWeb/detalle/'+this.contrato+'/'+this.orden);
                        const datos1=response1.data;
                        if(datos1[0].existe=='si'){
                            datos1.forEach(element => {
                                this.detalle.push({
                                    'producto':element.Producto,
                                    'cantidad':element.Cantidad,
                                    'precio':element.Precio
                                })
                            });
                        }
                        this.respuesta='Transacción exitosa';
                        this.verify=1;
                    }
                    //grabar codigo de respuesta transbank
                    let saveResponse='Codigo Nulo';
                    const par={
                        'response':this.codigo,
                        'orden': this.orden,
                        'monto': parseFloat(this.monto)
                    };
                    const res = await axios.put('https://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/transbank/webpayplus/response/save/'+this.contrato,par);
                    const dat = res.data;
                    if(dat=='si'){ 
                        saveResponse='Codigo Ok';
                        if(parseInt(this.codigo)==0){
                            await this.sincronizaStock();
                        }
                    };
                    console.log(saveResponse);
                } else {
                    //error de proceso de pago no terminado
                    this.respuesta='Solicitud sin respuesta'
                    this.verify=2;
                };
                this.token='';
                localStorage.removeItem('tokenCompra');
            } catch (error) {
                console.log(error)
            }
        },
        format_fecha(date){
            var d = new Date(date);
            date = [
                ('0' + d.getDate()).slice(-2),
                ('0' + (d.getMonth() + 1)).slice(-2),
                d.getFullYear()
            ].join('-');
            return date;
        },
        format_hora(hour){
            var d = new Date(hour);
            hour = [
                ('0' + d.getHours()).slice(-2),
                ('0' + d.getMinutes()).slice(-2),
                ('0' + d.getSeconds()).slice(-2)
            ].join(':');
            return hour;
        },
        async sincronizaStock(){
            let params;
            let headers;
            try {
                params = {
	                "contrato":this.contrato,
	                "user":"SITIO"
                };
                headers = {
                    'Content-Type': 'application/json',
                };
                const response = await axios.post('http://www.app.bodeganet.cl/ApiEcommerce/public/api/v1/create/token/',params,{headers});
                const dato = response.data;
                console.log(dato.Token);
                if(dato.Token!='error'){
                    params = {
                        "contrato":this.contrato,
                        "portal":99,
                        "user":"SITIO"
                    }
                    headers = {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + dato.Token
                    };
                    const result = await axios.post('http://www.app.bodeganet.cl/ApiEcommerce/public/api/v1/WEB/orders/',params,{headers});
                    const datos = result.data;
                    console.log(datos.message)
                }
            } catch (error) {
               console.log(error) 
            }
        },
        async enviar_comprobante(){
            let remitente = '';
            let destinatario = '';
            let asunto = '';
            let cuerpo = '';
            //orden
            let orden_numero = this.orden;
            let orden_tarjeta = this.tarjeta;
            let orden_fecha_operacion = this.fecha;
            let orden_hora_operacion = this.hora;
            let orden_monto_pagado = this.monto;
            //productos
            let producto_nombre = '';
            let producto_cantidad = '';
            let producto_precio = '';
            //despacho
            let despacho_nombre = '';
            let despacho_email = '';
            let despacho_telefono = '';
            let despacho_direccion = '';
            let despacho_region = '';
            let despacho_provincia = '';
            let despacho_comuna = '';
            //factura
            let factura_razon = '';
            let factura_giro = '';
            let factura_email = '';
            let factura_telefono = '';
            let factura_direccion = '';
            let factura_region = '';
            let factura_provincia = '';
            let factura_comuna = '';

            try {
                //datos del despacho
                const response1 = await axios.get('https://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/leer/ordenWeb/despacho/'+this.contrato+'/'+this.orden);
                const datos1=response1.data;
                if(datos1[0].existe=='si'){
                    const element=datos1[0];
                    despacho_nombre = element.Contacto;
                    despacho_email = element.Email;
                    despacho_telefono = element.Fono;
                    despacho_direccion = element.Direccion;
                    despacho_region = element.Region;
                    despacho_provincia = element.Ciudad;
                    despacho_comuna = element.Comuna;
                }
                //datos de la Factura
                const response2 = await axios.get('https://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/leer/ordenWeb/factura/'+this.contrato+'/'+this.orden);
                const datos2=response2.data;
                if(datos2[0].existe=='si'){
                    const element=datos2[0];
                    factura_razon = element.Razon;
                    factura_giro = element.Giro;
                    factura_email = element.Email;
                    factura_telefono = element.Fono;
                    factura_direccion = element.Direccion;
                    factura_region = element.Region;
                    factura_provincia = element.Ciudad;
                    factura_comuna = element.Comuna;
                }

                //generar el body html del correo
                let body = `
                <table width="100%">
                <tr>
                    <td style="width: 20%;">&nbsp;</td>
                    <td style="width: 60%;">
                    <table width="100%">
                        <tr>
                        <td style="width: 100%; text-align:center">
                            <img src="" alt="ok"></v-img>
                        </td>
                        </tr>
                        <tr>
                        <td style='width: 100%; text-align:center'>
                            <h1>Comprobante de pago</h1>
                        </td>
                        </tr>
                        <tr>
                        <td style='width: 100%; text-align:center; background:#0a2f42'>
                            <h4 style="color:white; margin-top:20px">Detalle de compra:</h4>
                        </td>
                        </tr>
                        <tr>
                        <td style="width: 100%;">
                            <table width="100%">
                            <tr>
                                <td style="width: 50%;"><strong>Producto</strong></td>
                                <td style="width: 25%; text-align:center" ><strong>Cantidad</strong></td>
                                <td style="width: 25%; text-align:center" ><strong>Precio</strong></td>
                            </tr>
                `;
                this.detalle.forEach(element => {
                    producto_nombre=element.producto;
                    producto_cantidad=element.cantidad;
                    producto_precio=element.precio;
                    body = body +
                    `<tr>
                    <td style="width: 50%;">` + producto_nombre + `</td>
                    <td style="width: 25%; text-align:center" >` + producto_cantidad + `</td>
                    <td style="width: 25%; text-align:center" >` + new Intl.NumberFormat("de-DE").format(parseFloat(producto_precio)) + `</td>
                    </tr>                          
                    `
                });                            
                body = body +
                    `</table>                            
                        </td>
                        </tr>
                        <tr>
                        <td style="width: 100%; text-align:center; background:#0a2f42">
                            <h4 style="color:white; margin-top:20px">Detalle de pago:</h4>
                        </td>
                        </tr>
                        <tr>
                        <td style="width: 100%;">
                            <table width="100%">
                            <tr>
                                <td style="width: 20%;">N° de orden</td>
                                <td style="width: 80%;">` + orden_numero + `</td>
                            </tr>
                            <tr>
                                <td style="width: 20%;">N° de Tarjeta</td>
                                <td style="width: 80%;">` + orden_tarjeta + `</td>
                            </tr>
                            <tr>
                                <td style="width: 20%;">Fecha operación</td>
                                <td style="width: 80%;">` + orden_fecha_operacion + `</td>
                            </tr>
                            <tr>
                                <td style="width: 20%;">Hora operación</td>
                                <td style="width: 80%;">` + orden_hora_operacion + `</td>
                            </tr>
                            <tr>
                                <td style="width: 20%;">Monto pagado</td>
                                <td style="width: 80%;">` + new Intl.NumberFormat("de-DE").format(parseFloat(orden_monto_pagado)) + `</td>
                            </tr>
                            </table>
                        </td>
                        </tr>
                        <tr>
                        <td style="width: 100%; text-align:center; background:#0a2f42">
                            <h4 style="color:white; margin-top:20px">Detalle de despacho:</h4>
                        </td>
                        </tr>
                        <tr>
                        <td style="width: 100%;">
                            <table width="100%">
                            <tr>
                                <td style="width: 20%;">Nombre</td>
                                <td style="width: 80%;">` + despacho_nombre + `</td>
                            </tr>
                            <tr>
                                <td style="width: 20%;">Email</td>
                                <td style="width: 80%;">` + despacho_email + `</td>
                            </tr>
                            <tr>
                                <td style="width: 20%;">Telefono</td>
                                <td style="width: 80%;">` + despacho_telefono + `</td>
                            </tr>
                            <tr>
                                <td style="width: 20%;">Direccion</td>
                                <td style="width: 80%;">` + despacho_direccion + `</td>
                            </tr>
                            <tr>
                                <td style="width: 20%;">Región</td>
                                <td style="width: 80%;">` + despacho_region + `</td>
                            </tr>
                            <tr>
                                <td style="width: 20%;">Provincia</td>
                                <td style="width: 80%;">` + despacho_provincia + `</td>
                            </tr>
                            <tr>
                                <td style="width: 20%;">Comuna/Ciudad</td>
                                <td style="width: 80%;">` + despacho_comuna + `</td>
                            </tr>
                            </table>
                        </td>
                        </tr>
                        <tr>
                        <td style="width: 100%; text-align:center; background:#0a2f42">
                            <h4 style="color:white; margin-top:20px">Detalle de facturación:</h4>
                        </td>
                        </tr>
                        <tr>
                        <td style="width: 100%;">
                            <table width="100%">
                            <tr>
                                <td style="width: 20%;">Razon Social</td>
                                <td style="width: 80%;">` + factura_razon + `</td>
                            </tr>
                            <tr>
                                <td style="width: 20%;">Giro</td>
                                <td style="width: 80%;">` + factura_giro + `</td>
                            </tr>
                            <tr>
                                <td style="width: 20%;">Email</td>
                                <td style="width: 80%;">` + factura_email + `</td>
                            </tr>
                            <tr>
                                <td style="width: 20%;">Telefono</td>
                                <td style="width: 80%;">` + factura_telefono + `</td>
                            </tr>
                            <tr>
                                <td style="width: 20%;">Direccion</td>
                                <td style="width: 80%;">` + factura_direccion + `</td>
                            </tr>
                            <tr>
                                <td style="width: 20%;">Región</td>
                                <td style="width: 80%;">` + factura_region + `</td>
                            </tr>
                            <tr>
                                <td style="width: 20%;">Provincia</td>
                                <td style="width: 80%;">` + factura_provincia + `</td>
                            </tr>
                            <tr>
                                <td style="width: 20%;">Comuna/Ciudad</td>
                                <td style="width: 80%;">` + factura_comuna + `</td>
                            </tr>
                            </table>
                        </td>
                        </tr>      
                    </table>
                    </td>
                    <td style="width: 20%;">&nbsp;</td>
                </tr>
                </table>`;
                //enviamos el correo
                remitente = 'ventas@maxel-lubricantes.cl';
                //destinatario = despacho_email;
                destinatario = this.form_email;
                asunto = 'Comprobante de Pago';
                cuerpo = body;
                if(this.form_email!=null){
                    if(this.form_email.trim()!=''){
                        await this.envioEmail(remitente, destinatario, asunto, cuerpo);
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },
        async envioEmail(remitente, destinatario, asunto, cuerpo){
            try {
                this.send=0;
                if (destinatario.trim()!=''){
                    let InstFormData = new FormData();
                    InstFormData.append('empresa' , 'Maxel Lubricantes');
                    InstFormData.append('remitente' , remitente);
                    InstFormData.append('destinatario' , destinatario);
                    InstFormData.append('asunto' , asunto);
                    InstFormData.append('cuerpo' , cuerpo);
                    const response= await axios.post('http://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/server/sender/email/', InstFormData , {headers : {'content-type': 'multipart/form-data'}});
                    console.log('send',response.data)
                    if(response.data!='enviado'){
                        this.form_email='';
                        this.send=2
                    } else {
                        this.form_email='';
                        this.send=1
                    }
                }                
            } catch (error) {
                console.log('send', error);
                this.form_email='';
                this.send=2
            }
        },
 
    }
}
</script>